import React, { useEffect, useState } from "react";
import Awareness from "../pages/Awareness";
import { useNavigate } from "react-router-dom";
import { encode as base64_encode } from "base-64";
import { ApiName } from "../Constant/ApiName";
import { fetchApiGet } from "../utils/FetchApi";
import tagManagerEvents from "../utils/GoogleTagManager";
import TagManager from "react-gtm-module";

type Props = {
  jwtToken: string | null;
};

let adNetwork = "";
const AwarenessScreen = (props: Props) => {
  const navigate = useNavigate();
  const [utm, setUtm] = useState<string | null>("");

  useEffect(() => {
    if (props.jwtToken != null) {
      handleUtmGet();
    }
  }, [props.jwtToken]);

  useEffect(() => {
    setTimeout(() => {
      gtmInitialization();
    }, 1000);
  }, []);

  const gtmInitialization = () => {
    const tagManagerArgs = {
      gtmId: "GTM-5JFD9W2K",
    };
    TagManager.initialize(tagManagerArgs);
  };

  const handleUtmGet = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const utmSource: string | null = searchParams.get("utm_source");
    setUtm(utmSource);
    if (utmSource) {
      handleCheckUtms(utmSource);
    }
    handleGetFlow(utmSource);
  };

  const handleCheckUtms = async (utm: string | null) => {
    try {
      let response = await fetchApiGet(props.jwtToken, ApiName.utm_list);

      if (response.status === 200) {
        {
          response.response.map((e: any) => {
            if (utm === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (error) {
      console.log("check utm error", error);
    }
  };

  const handleGetFlow = async (_utm: string | null) => {
    try {
      let endPoint = `${ApiName.awarenessFLow}?utm_source=${_utm}`;
      let response = await fetchApiGet(props.jwtToken, endPoint);
      if (response.response.code === "0" && response.status === 200) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          tagManagerEvents("SUBSCRIBE", "econTikTok");
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("SUBSCRIBE", "econceptions");
        } else {
          tagManagerEvents("SUBSCRIBE", _utm);
        }
        navigateToHome(response.response.msisdn, response.response.package);
      } else if (
        response.response.code === "1" &&
        response.response.desc.toLowerCase() === "already subscribe"
      ) {
        navigateTolanding();
      } else {
        tagManagerEvents("SUBSCRIBE_FAIL", _utm);
        navigateTolanding();
      }
    } catch (error) {
      navigateTolanding();
    }
  };

  const navigateToHome = (msisdn: any, subPackage: any) => {
    let url = window.location.origin;
    let encodedMsisdn = base64_encode(msisdn);
    let _package = "";
    if (subPackage.toLowerCase() === "postpaid") {
      _package = "postpaid";
    } else {
      _package = "prepaid";
    }
    let encodedPackage = base64_encode(_package);
    url = `${url}/home?user=${encodedMsisdn}&package=${encodedPackage}`;

    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    window.location.replace(url);
  };

  const navigateTolanding = () => {
    let url = window.location.origin;
    url = `${url}/landing`;
    window.location.replace(url);
  };
  return <Awareness />;
};

export default AwarenessScreen;
